.googleBtn {
  display: flex;
  align-items: center;
  width: 156px;
  max-width: 156px;
  padding: 5px;
  background-color: #4285f4;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;

  &:focus,
  &:hover {
    background-color: #1a638d;
  }
}

.icon {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.title {
  margin: 0 auto;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 768px)  {
  .facebookBtn {
    margin-left: 0;
    margin-top: 20px;
  }
}