.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.3s, opacity 0.3s;
}

.overlayIsVisible {
  opacity: 1;
  visibility: visible;
}

.backdrop {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(#1976d2, 0.1);
}
