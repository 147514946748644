.phoneInputContainer {
    font-family: inherit !important;
}


.input {
    width: 100% !important;
    margin-bottom: 0.8rem !important;
}

.btnFlag {
    height: 85%;
}