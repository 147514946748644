.socialButtons {
  display: flex;
  margin-bottom: 20px;
}

.submitError {
  margin-bottom: 15px !important;
}

@media (max-width: 768px)  {
  .socialButtons {
    flex-direction: column;
    align-items: center;
  }
}