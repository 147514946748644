button.MuiButton-root {
  border-radius: 24px;
}

div.MuiAccordion-root {
  border-radius: 1.5rem;
  margin-bottom: 1.5rem;
  //background: rgba(243, 245, 250, 0.72);
  background: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &:before {
    display: none;
  }

  &:first-of-type {
    border-radius: 1.5rem;
  }

  &:last-of-type {
    border-radius: 1.5rem;
  }
}

div.MuiAccordion-root.Mui-expanded {
  &:last-of-type {
    margin-bottom: 1.5rem;
  }
}

div.MuiAlert-root {
  border-radius: 1.5rem;
}

div.MuiAccordionSummary-content {
  font-weight: 500;
  font-size: 1.2rem;
  margin: 20px 5px;
}

div.MuiAccordionDetails-root {
  padding: 30px 50px;
}

.MuiPaper-root {
  box-shadow: 0 4px 10px 0 rgba(0, 16, 40, 0.20) !important;
}

fieldset.MuiOutlinedInput-notchedOutline {
  border-radius: 0 !important;
  box-shadow: none !important;
}

button.MuiButton-root {
  font-size: 16px;
  font-weight: 500;
  padding: 12px 30px;
  box-shadow: none !important;
  line-height: 19px;
  min-height: 45px;

  @media screen and (max-width: 520px) {
    padding: 6px 18px;
  }
}

input.MuiInputBase-input {
  padding: 11px 16px !important;
  font-size: 16px;
  color: #000;
}

div.MuiSelect-select {
  padding: 11px 32px 11px 16px !important;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
}

label.MuiInputLabel-root {
  top: -4px;
}

.MuiPaper-root {
  & > .MuiTableContainer-root {
    @media screen and (max-width: 900px) {
      max-width: 500px;
    }

    @media screen and (max-width: 480px) {
      max-width: 270px;
    }

    @media screen and (max-width: 400px) {
      max-width: 240px;
    }

    @media screen and (max-width: 350px) {
      max-width: 200px;
    }

    &.table_full {
      max-width: 100%;
    }
  }
}
