.phoneInputContainer {
  font-family: inherit !important;
}

.passInput {
  position: relative;
}

.input {
  width: 100% !important;
  margin-bottom: 0.8rem !important;
}

.btnFlag {
  height: 85%;
}

.passVisibleBtn {
  position: absolute;
  top: 40%;
  right: 10px;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
}

.submitError {
  &:last-child {
    margin-bottom: 15px;
  }
}
