div.paper {
  padding: 1rem;
  border-radius: 50px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  animation: fade-in-from-top 0.8s cubic-bezier(0.55, 0.5, 0.45, 0.5); // ?
  @media (min-width: 900px) {
    min-width: 760px;
    margin-left: -15px;
    padding: 2rem 2.2rem;
  }
}

.formIcon {
  flex-shrink: 0;
}

.paymentSystemsRow {
  display: flex;
  justify-content: center;
  column-gap: 24px;
  margin-bottom: 16px;
}

@media screen and (max-width: 600px) {
  .formIcon {
    display: none;
  }
}
