@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

@keyframes fade-in-from-top {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translate(0, -20px);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
}
