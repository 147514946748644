.homePage {
  position: relative;
  height: calc(100% - 64px);
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.guestPaymentPageContainer {
  min-height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

@media (max-width: 576px)  {
  .homePage {
    height: initial;
  }
}