@font-face {
  font-family: 'Inter';
  src: local('Inter ExtraBold'), local('Inter-ExtraBold'),
  url('../assets/fonts/Inter-ExtraBold.woff2') format('woff2'),
  url('../assets/fonts/Inter-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Bold Italic'), local('Inter-BoldItalic'),
  url('../assets/fonts/Inter-BoldItalic.woff2') format('woff2'),
  url('../assets/fonts/Inter-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Bold'), local('Inter-Bold'),
  url('../assets/fonts/Inter-Bold.woff2') format('woff2'),
  url('../assets/fonts/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Black Italic'), local('Inter-BlackItalic'),
  url('../assets/fonts/Inter-BlackItalic.woff2') format('woff2'),
  url('../assets/fonts/Inter-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter ExtraBold Italic'), local('Inter-ExtraBoldItalic'),
  url('../assets/fonts/Inter-ExtraBoldItalic.woff2') format('woff2'),
  url('../assets/fonts/Inter-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Black'), local('Inter-Black'),
  url('../assets/fonts/Inter-Black.woff2') format('woff2'),
  url('../assets/fonts/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'),
  url('../assets/fonts/Inter-Medium.woff2') format('woff2'),
  url('../assets/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'),
  url('../assets/fonts/Inter-Regular.woff2') format('woff2'),
  url('../assets/fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Italic'), local('Inter-Italic'),
  url('../assets/fonts/Inter-Italic.woff2') format('woff2'),
  url('../assets/fonts/Inter-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Light'), local('Inter-Light'),
  url('../assets/fonts/Inter-LightBETA.woff2') format('woff2'),
  url('../assets/fonts/Inter-LightBETA.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter SemiBold Italic'), local('Inter-SemiBoldItalic'),
  url('../assets/fonts/Inter-SemiBoldItalic.woff2') format('woff2'),
  url('../assets/fonts/Inter-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Thin Italic'), local('Inter-ThinItalic'),
  url('../assets/fonts/Inter-ThinItalicBETA.woff2') format('woff2'),
  url('../assets/fonts/Inter-ThinItalicBETA.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter ExtraLight Italic'), local('Inter-ExtraLightItalic'),
  url('../assets/fonts/Inter-ExtraLightItalicBETA.woff2') format('woff2'),
  url('../assets/fonts/Inter-ExtraLightItalicBETA.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter SemiBold'), local('Inter-SemiBold'),
  url('../assets/fonts/Inter-SemiBold.woff2') format('woff2'),
  url('../assets/fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Medium Italic'), local('Inter-MediumItalic'),
  url('../assets/fonts/Inter-MediumItalic.woff2') format('woff2'),
  url('../assets/fonts/Inter-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Light Italic'), local('Inter-LightItalic'),
  url('../assets/fonts/Inter-LightItalicBETA.woff2') format('woff2'),
  url('../assets/fonts/Inter-LightItalicBETA.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Thin'), local('Inter-Thin'),
  url('../assets/fonts/Inter-ThinBETA.woff2') format('woff2'),
  url('../assets/fonts/Inter-ThinBETA.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter ExtraLight'), local('Inter-ExtraLight'),
  url('../assets/fonts/Inter-ExtraLightBETA.woff2') format('woff2'),
  url('../assets/fonts/Inter-ExtraLightBETA.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}


// Old Font

//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
//  url('../assets/fonts/Raleway-ExtraBold.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-ExtraBold.woff') format('woff');
//  font-weight: bold;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway Bold Italic'), local('Raleway-BoldItalic'),
//  url('../assets/fonts/Raleway-BoldItalic.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-BoldItalic.woff') format('woff');
//  font-weight: bold;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway Bold'), local('Raleway-Bold'),
//  url('../assets/fonts/Raleway-Bold.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-Bold.woff') format('woff');
//  font-weight: bold;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway Black Italic'), local('Raleway-BlackItalic'),
//  url('../assets/fonts/Raleway-BlackItalic.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-BlackItalic.woff') format('woff');
//  font-weight: 900;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway ExtraBold Italic'), local('Raleway-ExtraBoldItalic'),
//  url('../assets/fonts/Raleway-ExtraBoldItalic.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-ExtraBoldItalic.woff') format('woff');
//  font-weight: bold;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway Black'), local('Raleway-Black'),
//  url('../assets/fonts/Raleway-Black.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-Black.woff') format('woff');
//  font-weight: 900;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway Medium'), local('Raleway-Medium'),
//  url('../assets/fonts/Raleway-Medium.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-Medium.woff') format('woff');
//  font-weight: 500;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway Regular'), local('Raleway-Regular'),
//  url('../assets/fonts/Raleway-Regular.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-Regular.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway Italic'), local('Raleway-Italic'),
//  url('../assets/fonts/Raleway-Italic.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-Italic.woff') format('woff');
//  font-weight: normal;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway Light'), local('Raleway-Light'),
//  url('../assets/fonts/Raleway-Light.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-Light.woff') format('woff');
//  font-weight: 300;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway SemiBold Italic'), local('Raleway-SemiBoldItalic'),
//  url('../assets/fonts/Raleway-SemiBoldItalic.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-SemiBoldItalic.woff') format('woff');
//  font-weight: 600;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway Thin Italic'), local('Raleway-ThinItalic'),
//  url('../assets/fonts/Raleway-ThinItalic.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-ThinItalic.woff') format('woff');
//  font-weight: 100;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway ExtraLight Italic'), local('Raleway-ExtraLightItalic'),
//  url('../assets/fonts/Raleway-ExtraLightItalic.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-ExtraLightItalic.woff') format('woff');
//  font-weight: 200;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
//  url('../assets/fonts/Raleway-SemiBold.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-SemiBold.woff') format('woff');
//  font-weight: 600;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'),
//  url('../assets/fonts/Raleway-MediumItalic.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-MediumItalic.woff') format('woff');
//  font-weight: 500;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway Light Italic'), local('Raleway-LightItalic'),
//  url('../assets/fonts/Raleway-LightItalic.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-LightItalic.woff') format('woff');
//  font-weight: 300;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway Thin'), local('Raleway-Thin'),
//  url('../assets/fonts/Raleway-Thin.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-Thin.woff') format('woff');
//  font-weight: 100;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Raleway';
//  src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'),
//  url('../assets/fonts/Raleway-ExtraLight.woff2') format('woff2'),
//  url('../assets/fonts/Raleway-ExtraLight.woff') format('woff');
//  font-weight: 200;
//  font-style: normal;
//  font-display: swap;
//}

