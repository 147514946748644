//colors
$primary: #1976d2;
$darkGray: #515766;
$lightGray: #aaa;
$danger: #c53939;

$darkText: #2f4479;
$grayText: #696969;

$authBtnColor: #ffffff;
